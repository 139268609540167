import { useMutation, useQueryClient } from "@tanstack/react-query";
import { customFetch } from "../../../util/axios";
import { DeleteFrequentlyAskedQuestionByIdParams } from "../../frequentlyAskedQuestions/schemas";
import { AxiosError } from "axios";

interface DeleteFrequentlyAskedQuestionResponse {}

const deleteFrequentlyAskedQuestionById = async (
  deleteFrequentlyAskedQuestionByIdParams: DeleteFrequentlyAskedQuestionByIdParams,
) => {
  const { frequentlyAskedQuestionId } = deleteFrequentlyAskedQuestionByIdParams;
  const response = await customFetch.delete(
    `/frequently-asked-questions/${frequentlyAskedQuestionId}`,
  );
  return response.data;
};

export const useDeleteFrequentlyAskedQuestionById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    DeleteFrequentlyAskedQuestionResponse,
    AxiosError | Error,
    DeleteFrequentlyAskedQuestionByIdParams
  >({
    mutationFn: deleteFrequentlyAskedQuestionById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-question-categories"],
      });
      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-questions"],
      });
    },
  });

  return mutation;
};
