import { z } from "zod";
import { createFrequentlyAskedQuestionSchema } from "./createFrequentlyAskedQuestionSchema";

export const updateFrequentlyAskedQuestionByIdSchema = z.object({
  params: z.object({
    frequentlyAskedQuestionId: z.coerce.number().int().safe().nonnegative(),
  }),
  body: createFrequentlyAskedQuestionSchema.shape.body,
});

export type UpdateFrequentlyAskedQuestionByIdDto = z.infer<
  typeof updateFrequentlyAskedQuestionByIdSchema
>;
