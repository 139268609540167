import { AxiosError } from "axios";
import { useEffect } from "react";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { customFetch } from "../../../util/axios";
import { FrequentlyAskedQuestionResponse } from "../../frequentlyAskedQuestions/types";
import {
  GetAllFrequentlyAskedQuestionsByCategoryIdParamsDto,
  GetAllFrequentlyAskedQuestionsByCategoryIdQueryDto,
} from "../schemas";

export interface GetAllFrequentlyAskedQuestionsByCategoryIdResponse {
  frequentlyAskedQuestionsCount: number;
  frequentlyAskedQuestionsTotalPages: number;
  frequentlyAskedQuestionsHasNextPage: boolean;
  frequentlyAskedQuestions: FrequentlyAskedQuestionResponse[];
}

interface GetAllFrequentlyAskedQuestionsByCategoryIdData {
  params: GetAllFrequentlyAskedQuestionsByCategoryIdParamsDto;
  query: GetAllFrequentlyAskedQuestionsByCategoryIdQueryDto;
}

export const getAllFrequentlyAskedQuestionsByCategoryId = async (
  frequentlyAskedQuestionCategoryId: number,
  query: GetAllFrequentlyAskedQuestionsByCategoryIdQueryDto,
) => {
  const response = await customFetch.get(
    `/frequently-asked-question-categories/${frequentlyAskedQuestionCategoryId}/frequently-asked-questions`,
    { params: query },
  );
  return response.data;
};

export const useGetAllFrequentlyAskedQuestionsByCategoryId = (
  getAllFrequentlyAskedQuestionsByCategoryIdData: GetAllFrequentlyAskedQuestionsByCategoryIdData,
  options?: UseQueryOptions<
    GetAllFrequentlyAskedQuestionsByCategoryIdResponse,
    AxiosError | Error
  >,
) => {
  const queryClient = useQueryClient();

  const {
    params: { frequentlyAskedQuestionCategoryId },
    query: getAllFrequentlyAskedQuestionsByCategoryIdQuery,
  } = getAllFrequentlyAskedQuestionsByCategoryIdData;

  const query = useQuery<
    GetAllFrequentlyAskedQuestionsByCategoryIdResponse,
    AxiosError | Error
  >({
    queryKey: [
      "frequently-asked-question-categories",
      frequentlyAskedQuestionCategoryId,
      "frequently-asked-questions",
      getAllFrequentlyAskedQuestionsByCategoryIdQuery,
    ],
    queryFn: () =>
      getAllFrequentlyAskedQuestionsByCategoryId(
        frequentlyAskedQuestionCategoryId,
        getAllFrequentlyAskedQuestionsByCategoryIdQuery,
      ),
    keepPreviousData: true,
    ...options,
  });

  const pageIndex =
    getAllFrequentlyAskedQuestionsByCategoryIdQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.frequentlyAskedQuestionsHasNextPage) {
      const nextParams = {
        ...getAllFrequentlyAskedQuestionsByCategoryIdQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: [
          "frequently-asked-question-categories",
          frequentlyAskedQuestionCategoryId,
          "frequently-asked-questions",
          nextParams,
        ],
        queryFn: () =>
          getAllFrequentlyAskedQuestionsByCategoryId(
            frequentlyAskedQuestionCategoryId,
            nextParams,
          ),
      });
    }
  }, [
    data,
    getAllFrequentlyAskedQuestionsByCategoryIdQuery,
    frequentlyAskedQuestionCategoryId,
    isPreviousData,
    pageIndex,
    queryClient,
  ]);

  return query;
};
