import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateFrequentlyAskedQuestionsDto } from "../schemas/updateFrequentlyAskedQuestionsSchema";
import { FrequentlyAskedQuestionResponse } from "../types";
import { customFetch } from "../../../util/axios";
import { AxiosError } from "axios";

interface UpdateFrequentlyAskedQuestionsResponse {
  frequentlyAskedQuestions: (FrequentlyAskedQuestionResponse & {
    updatedBy: { id: number };
  })[];
}

const updateFrequentlyAskedQuestions = async (
  updateFrequentlyAskedQuestionsDto: UpdateFrequentlyAskedQuestionsDto,
) => {
  const { body } = updateFrequentlyAskedQuestionsDto;
  const response = await customFetch.patch(`/frequently-asked-questions`, body);
  return response.data;
};

export const useUpdateFrequentlyAskedQuestions = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateFrequentlyAskedQuestionsResponse,
    AxiosError | Error,
    UpdateFrequentlyAskedQuestionsDto
  >({
    mutationFn: updateFrequentlyAskedQuestions,
    onSuccess: (data, variables) => {
      variables.body.frequentlyAskedQuestions.forEach(
        (frequentlyAskedQuestion) => {
          const frequentlyAskedQuestionId = frequentlyAskedQuestion.id;
          const oldCategoryId = frequentlyAskedQuestion.category.id;

          queryClient.invalidateQueries({
            queryKey: ["frequently-asked-questions", frequentlyAskedQuestionId],
          });
          queryClient.invalidateQueries({
            queryKey: ["frequently-asked-question-categories", oldCategoryId],
          });
        },
      );

      data.frequentlyAskedQuestions.forEach((frequentlyAskedQuestion) => {
        const newCategoryId = frequentlyAskedQuestion.category.id;
        queryClient.invalidateQueries({
          queryKey: ["frequently-asked-question-categories", newCategoryId],
        });
      });
    },
  });

  return mutation;
};
