export class FrequentlyAskedQuestion {
  id: number;
  question: string;
  answer: string;
  priority: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    id: number;
    name: string;
  };

  static get sortableColumns(): Partial<
    Record<keyof FrequentlyAskedQuestion, keyof FrequentlyAskedQuestion>
  > {
    return {
      id: "id",
      question: "question",
      answer: "answer",
      priority: "priority",
      category: "category",
      createdAt: "createdAt",
      updatedAt: "updatedAt",
    } as const;
  }

  static get stringFilterableColumns(): Partial<
    Record<keyof FrequentlyAskedQuestion, keyof FrequentlyAskedQuestion>
  > {
    return {
      id: "id",
      question: "question",
      answer: "answer",
      priority: "priority",
      category: "category",
    } as const;
  }

  static get datetimeRangeFilterableColumns(): Partial<
    Record<keyof FrequentlyAskedQuestion, keyof FrequentlyAskedQuestion>
  > {
    return {
      createdAt: "createdAt",
      updatedAt: "updatedAt",
    } as const;
  }
}
