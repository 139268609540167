import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import Classes from "../Pages/Classes/Classes";
import ClassDetail from "../Pages/Classes/ClassDetail";
import ExpressWay from "../Pages/ExpressWay";
import AdminRoles from "../Pages/AdminRoles";
import Announcement from "../Pages/Announcement";
import Tickets from "../Pages/Tickets";
import AdminUsers from "../Pages/Users";
import Students from "../Pages/Student";
import Activites from "../Pages/Activities";
import ExpertAdvisors from "../Pages/ExpertAdvisors";
import Indicators from "../Pages/Indicators";
import Scripts from "../Pages/Script";
import Utilities from "../Pages/Utilities";
import AdminRents from "../Pages/Rents";
import Grants from "../Pages/Grants";
import Membership from "../Pages/Membership";
import FaqsCategory from "../Pages/Faqs/FaqsCategory";
import Statistics from "../Pages/Statistics";
import IndicatorDetail from "../Pages/Indicators/IndicatorDetails";
import AnnouncementDetail from "../Pages/Announcement/AnnouncementDetail";
import ScriptDetail from "../Pages/Script/ScriptDetails";
import UtilityDetails from "../Pages/Utilities/utilityDetails";
import ExpertAdvisorDetails from "../Pages/ExpertAdvisors/expertAdvsiorsDetails";
import TicketDetail from "../Pages/Tickets/TicketDetail";
import MemberDetail from "../Pages/Users/Memberdetail";
import Gifts from "../Pages/Gifts";
import GiftsCategory from "../Pages/Gifts/GiftsCategory";
import MemeberDetails from "../Pages/ExpressWay/MemeberDetails";
import ActivityDetail from "../Pages/Activities/Details";
import ActivityQRCode from "../Pages/Activities/ActivityQRCode";
import PrintUserInfo from "../Pages/Users/PrintUserInfo";
import ActivityRollCall from "../Pages/Activities/ActivityRollCall";
import PrintRepeaterInfo from "../Pages/Users/PrintRepeaterInfo";
import Profile from "../Pages/Profile";
import ChangePassword from "../Pages/ChangePassword";
import Library from "../Pages/Library";
import LibraryDetail from "../Pages/Library/LibraryDetails";
import TicketSettings from "../Pages/Tickets/TicketSettings";
import Referal from "../Pages/Referal";
import TicketGroupSettings from "../Pages/Tickets/TicketGroupSettings";
import AnnouncementGroupSettings from "../Pages/Announcement/AnnouncementGroupSettings";
import PageNotFound from "../Pages/PageNotFound";
import VpsManagement from "../Pages/VpsManagement";
import { ManageUserPermission } from "../Pages/PermissonManagement/Users";
import { ManageRolesPermission } from "../Pages/PermissonManagement/Roles";
import Bills from "../Pages/Bills";
import BillDetails from "../Pages/Bills/BillDetails";
import Forbidden from "../Pages/Forbidden";
import PlanCategories from "../Pages/PlanCategories/PlanCategories";
import Plans from "../Pages/Plans/Plans";
import GemsAdvertisements from "../Pages/GemsAdvertisements/GemsAdvertisements";
import ConsumerDetailLayout from "../Pages/Users/ConsumerDetail";
import FrequentlyAskedQuestionCategories from "../Pages/FrequentlyAskedQuestionCategories/FrequentlyAskedQuestionCategories";
import FrequentlyAskedQuestionsByCategoryId from "../Pages/FrequentlyAskedQuestionCategories/FrequentlyAskedQuestionsByCategoryId";

export const publicRoutes = [
  {
    path: "/",
    element: Login,
    sideBar: false,
    navBar: false,
    exact: true,
  },
  {
    path: "/login",
    element: Login,
    sideBar: false,
    navBar: false,
  },

  {
    path: "/users/print/:id",
    element: PrintUserInfo,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/users/repeater/print/:id",
    element: PrintRepeaterInfo,
    sideBar: false,
    navBar: false,
  },

  {
    path: "/pageNotFound",
    element: PageNotFound,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/forbidden",
    element: Forbidden,
    sideBar: false,
    navBar: false,
  },
];

export const privateRoutes = [
  {
    path: "/home",
    element: Dashboard,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/classes",
    element: Classes,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/expressway",
    element: ExpressWay,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/adminroles",
    element: AdminRoles,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/announcements",
    element: Announcement,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/tickets",
    element: Tickets,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/plan-categories",
    element: PlanCategories,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/plans",
    element: Plans,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/users",
    element: AdminUsers,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/students",
    element: Students,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/activities",
    element: Activites,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/activities/:id",
    element: ActivityDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/expert-advisors",
    element: ExpertAdvisors,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/expert-advisors/:id",
    element: ExpertAdvisorDetails,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/indicators",
    element: Indicators,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/scripts",
    element: Scripts,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/utilities",
    element: Utilities,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/grants",
    element: Grants,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/membership",
    element: Membership,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/faqs/category",
    element: FaqsCategory,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/statistic",
    element: Statistics,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/rents",
    element: AdminRents,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/classes/:id",
    element: ClassDetail,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/indicators/:id",
    element: IndicatorDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/announcements/:id",
    element: AnnouncementDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/scripts/:id",
    element: ScriptDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/utilities/:id",
    element: UtilityDetails,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/tickets/:id",
    element: TicketDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/users/consumer/:id",
    element: ConsumerDetailLayout,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/users/:id",
    element: MemberDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/users/expressWay/:id",
    element: MemeberDetails,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/gifts",
    element: Gifts,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/gifts/category",
    element: GiftsCategory,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/gems-advertisements",
    element: GemsAdvertisements,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/activities/rqcode/:id",
    element: ActivityQRCode,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/activities/rollcall/:id",
    element: ActivityRollCall,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/profile",
    element: Profile,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/change-password",
    element: ChangePassword,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/library",
    element: Library,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/library/:id",
    element: LibraryDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/setting/ticket",
    element: TicketSettings,
    sideBar: true,
    navBar: true,
  },
  {
    path: "referal",
    element: Referal,
    sideBar: true,
    navBar: true,
  },
  {
    path: "tickets/group/settings",
    element: TicketGroupSettings,
    sideBar: true,
    navBar: true,
  },
  {
    path: "announcements/group/settings",
    element: AnnouncementGroupSettings,
    sideBar: true,
    navBar: true,
  },
  {
    path: "vpsManagement",
    element: VpsManagement,
    sideBar: true,
    navBar: true,
  },
  {
    path: "permission/roles",
    element: ManageRolesPermission,
    sideBar: true,
    navBar: true,
  },
  {
    path: "permission/users",
    element: ManageUserPermission,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/bills",
    element: Bills,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/bills/:id",
    element: BillDetails,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/frequently-asked-question-categories",
    element: FrequentlyAskedQuestionCategories,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/frequently-asked-question-categories/:frequentlyAskedQuestionCategoryId/frequently-asked-questions",
    element: FrequentlyAskedQuestionsByCategoryId,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/*",
    element: PageNotFound,
    sideBar: false,
    navBar: false,
  },
];
