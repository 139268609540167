import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { UpdateFrequentlyAskedQuestionCategoryByIdDto } from "../schemas/updateFrequentlyAskedQuestionCategoryByIdSchema";
import { FrequentlyAskedQuestionCategoryResponse } from "../types";
import { customFetch } from "../../../util/axios";

interface UpdateFrequentlyAskedQuestionCategoryResponse {
  frequentlyAskedQuestionCategory: FrequentlyAskedQuestionCategoryResponse;
}

const updateFrequentlyAskedQuestionCategoryById = async (
  updateFrequentlyAskedQuestionCategoryByIdDto: UpdateFrequentlyAskedQuestionCategoryByIdDto,
) => {
  const {
    params: { frequentlyAskedQuestionCategoryId },
    body,
  } = updateFrequentlyAskedQuestionCategoryByIdDto;
  const response = await customFetch.patch(
    `/frequently-asked-question-categories/${frequentlyAskedQuestionCategoryId}`,
    body,
  );
  return response.data;
};

export const useUpdateFrequentlyAskedQuestionCategoryById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateFrequentlyAskedQuestionCategoryResponse,
    AxiosError | Error,
    UpdateFrequentlyAskedQuestionCategoryByIdDto
  >({
    mutationFn: updateFrequentlyAskedQuestionCategoryById,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-question-categories"],
      }),
  });

  return mutation;
};
