import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { UpdateFrequentlyAskedQuestionCategoriesDto } from "../schemas/updateFrequentlyAskedQuestionCategoriesSchema";
import { FrequentlyAskedQuestionCategoryResponse } from "../types";
import { customFetch } from "../../../util/axios";

interface UpdateFrequentlyAskedQuestionCategoriesResponse {
  frequentlyAskedQuestionCategories: (FrequentlyAskedQuestionCategoryResponse & {
    updatedBy: { id: number };
  })[];
}

const updateFrequentlyAskedQuestionCategories = async (
  updateFrequentlyAskedQuestionCategoriesDto: UpdateFrequentlyAskedQuestionCategoriesDto,
) => {
  const { body } = updateFrequentlyAskedQuestionCategoriesDto;
  const response = await customFetch.patch(
    `/frequently-asked-question-categories`,
    body,
  );
  return response.data;
};

export const useUpdateFrequentlyAskedQuestionCategories = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateFrequentlyAskedQuestionCategoriesResponse,
    AxiosError | Error,
    UpdateFrequentlyAskedQuestionCategoriesDto
  >({
    mutationFn: updateFrequentlyAskedQuestionCategories,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-question-categories"],
      }),
  });

  return mutation;
};
