import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateFrequentlyAskedQuestionByIdDto } from "../schemas/updateFrequentlyAskedQuestionByIdSchema";
import { FrequentlyAskedQuestionResponse } from "../types";
import { customFetch } from "../../../util/axios";
import { AxiosError } from "axios";

interface UpdateFrequentlyAskedQuestionResponse {
  frequentlyAskedQuestion: FrequentlyAskedQuestionResponse;
}

const updateFrequentlyAskedQuestionById = async (
  updateFrequentlyAskedQuestionByIdDto: UpdateFrequentlyAskedQuestionByIdDto,
) => {
  const {
    params: { frequentlyAskedQuestionId },
    body,
  } = updateFrequentlyAskedQuestionByIdDto;
  const response = await customFetch.patch(
    `/frequently-asked-questions/${frequentlyAskedQuestionId}`,
    body,
  );
  return response.data;
};

export const useUpdateFrequentlyAskedQuestionById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateFrequentlyAskedQuestionResponse,
    AxiosError | Error,
    UpdateFrequentlyAskedQuestionByIdDto
  >({
    mutationFn: updateFrequentlyAskedQuestionById,
    onSuccess: (data, variables) => {
      const frequentlyAskedQuestionId =
        variables.params.frequentlyAskedQuestionId;
      const categoryId = data.frequentlyAskedQuestion.category.id;

      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-questions", frequentlyAskedQuestionId],
      });

      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-question-categories", categoryId],
      });
    },
  });

  return mutation;
};
