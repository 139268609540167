import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import {
  CreateFrequentlyAskedQuestionCategoryDto,
  createFrequentlyAskedQuestionCategorySchema,
  UpdateFrequentlyAskedQuestionCategoryByIdDto,
} from "../../../features/frequentlyAskedQuestionCategories/schemas";
import {
  useCreateFrequentlyAskedQuestionCategory,
  useUpdateFrequentlyAskedQuestionCategoryById,
} from "../../../features/frequentlyAskedQuestionCategories/api";
import { MRT_Row } from "material-react-table";
import { FrequentlyAskedQuestionCategoryResponse } from "../../../features/frequentlyAskedQuestionCategories/types";
import { formatErrorMessage } from "../../../util/utils";

interface CreateFrequentlyAskedQuestionCategoryModalProps {
  open: boolean;
  dataEditing: MRT_Row<FrequentlyAskedQuestionCategoryResponse> | undefined;
  onClose: () => void;
}

const CreateFrequentlyAskedQuestionCategoryModal = (
  props: CreateFrequentlyAskedQuestionCategoryModalProps,
) => {
  const { open, dataEditing, onClose } = props;
  const [keepValuesAfterSuccess, setKeepValuesAfterSuccess] = useState(false);

  const {
    mutate: mutateCreate,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    data: createData,
  } = useCreateFrequentlyAskedQuestionCategory();

  const {
    mutate: mutateUpdate,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    data: updateData,
    reset: updateReset,
  } = useUpdateFrequentlyAskedQuestionCategoryById();

  const form = useForm<CreateFrequentlyAskedQuestionCategoryDto>({
    resolver: zodResolver(
      createFrequentlyAskedQuestionCategorySchema.shape.body,
    ),
    defaultValues: useMemo(
      () => formatDefaultValues(dataEditing),
      [dataEditing],
    ),
  });

  const { register, handleSubmit, setError, formState, reset, control } = form;
  const { errors } = formState;

  const onCreationSubmit: SubmitHandler<
    CreateFrequentlyAskedQuestionCategoryDto
  > = (data) => {
    mutateCreate(data, {
      onSuccess: (data) => {
        if (!keepValuesAfterSuccess) {
          reset();
        }
        const { name } = data.frequentlyAskedQuestionCategory;
        toast.success(
          `Frequently asked question category "${name}" is created successfully.`,
        );
      },
      onError: (error) => {
        const message = formatErrorMessage(error);
        setError("root", { message });
      },
    });
  };

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  const onEditionSubmit: SubmitHandler<
    UpdateFrequentlyAskedQuestionCategoryByIdDto["body"]
  > = (data) => {
    if (!dataEditing) {
      return;
    }

    const params = {
      frequentlyAskedQuestionCategoryId: dataEditing.original.id,
    };
    mutateUpdate(
      { params, body: data },
      {
        onSuccess: (data) => {
          const { name } = data.frequentlyAskedQuestionCategory;
          toast.success(
            `Frequently asked question category "${name}" is updated successfully.`,
          );
        },
        onError: (error) => {
          const message = formatErrorMessage(error);
          setError("root", { message });
        },
      },
    );
  };

  const onSubmit = dataEditing ? onEditionSubmit : onCreationSubmit;
  const isSuccess = dataEditing ? isUpdateSuccess : isCreateSuccess;
  const isLoading = dataEditing ? isUpdateLoading : isCreateLoading;
  const data = dataEditing ? updateData : createData;

  useEffect(() => {
    reset(formatDefaultValues(dataEditing));
    updateReset();

    if (!open) {
      reset();
    }
  }, [open, updateReset, dataEditing, reset]);

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {dataEditing
          ? `Edit Frequently Asked Question Category with Id: ${dataEditing.original.id}`
          : "Create Frequently Asked Question Category"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            sx={{
              marginTop: "0.5rem",
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Name"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />

            <Stack direction="row" spacing={2}>
              <TextField
                style={{ flexGrow: 1 }}
                label="Priority"
                {...register("priority")}
                error={!!errors.priority}
                helperText={errors.priority?.message}
              />

              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="Is Active"
                  />
                )}
              />
            </Stack>

            {isSuccess && data ? (
              <Alert severity="success">{`Frequently asked question category "${data.frequentlyAskedQuestionCategory.name}" is ${dataEditing ? "updated" : "created"} successfully.`}</Alert>
            ) : null}
            {errors.root?.message ? (
              <Alert severity="error">{errors.root?.message}</Alert>
            ) : null}
          </Stack>
        </form>
      </DialogContent>

      <DialogActions sx={{ p: "1.25rem" }}>
        {!dataEditing ? (
          <FormControlLabel
            name="keepValuesAfterSuccess"
            control={
              <Checkbox
                checked={keepValuesAfterSuccess}
                onClick={() =>
                  setKeepValuesAfterSuccess(
                    (oldKeepValuesAfterSuccess) => !oldKeepValuesAfterSuccess,
                  )
                }
              />
            }
            label="Keep values after successful submission"
          />
        ) : null}
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={dataEditing ? <EditIcon /> : <CreateIcon />}
          variant="contained"
        >
          <span>{dataEditing ? "Edit " : "Create"}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const formatDefaultValues = (
  dataEditing: MRT_Row<FrequentlyAskedQuestionCategoryResponse> | undefined,
) => {
  if (!dataEditing) {
    return {
      priority: 1,
      isActive: true,
    };
  }

  const name = dataEditing.original.name;
  const priority = dataEditing.original.priority;
  const isActive = dataEditing.original.isActive;

  return {
    name,
    priority,
    isActive,
  };
};

export default CreateFrequentlyAskedQuestionCategoryModal;
