import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  GetFrequentlyAskedQuestionCategoryByIdResponse,
  useGetFrequentlyAskedQuestionCategoryById,
  useUpdateFrequentlyAskedQuestionCategoryById,
} from "../../../features/frequentlyAskedQuestionCategories/api";
import {
  CreateFrequentlyAskedQuestionCategoryDto,
  createFrequentlyAskedQuestionCategorySchema,
  UpdateFrequentlyAskedQuestionCategoryByIdDto,
} from "../../../features/frequentlyAskedQuestionCategories/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { formatErrorMessage } from "../../../util/utils";
import {
  Alert,
  Card,
  CardContent,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";

interface FrequentlyAskedQuestionCategoryDetailProps {
  frequentlyAskedQuestionCategoryId: number;
}

export const FrequentlyAskedQuestionCategoryDetail = (
  props: FrequentlyAskedQuestionCategoryDetailProps,
) => {
  const { frequentlyAskedQuestionCategoryId } = props;
  const {
    data: frequentlyAskedQuestionCategoryData,
    isLoading: isFrequentlyAskedQuestionCategoryLoading,
    isFetching: isFrequentlyAskedQuestionCategoryFetching,
    isError: isFrequentlyAskedQuestionCategoryError,
    error: frequentlyAskedQuestionCategoryError,
  } = useGetFrequentlyAskedQuestionCategoryById({
    params: { frequentlyAskedQuestionCategoryId },
    query: {},
  });

  const {
    mutate: mutateUpdate,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    data: updateData,
  } = useUpdateFrequentlyAskedQuestionCategoryById();

  const isFormDisabled =
    isFrequentlyAskedQuestionCategoryLoading ||
    isFrequentlyAskedQuestionCategoryFetching ||
    isFrequentlyAskedQuestionCategoryError ||
    isUpdateLoading;

  const form = useForm<CreateFrequentlyAskedQuestionCategoryDto>({
    resolver: zodResolver(
      createFrequentlyAskedQuestionCategorySchema.shape.body,
    ),
    defaultValues: useMemo(
      () => formatDefaultValues(frequentlyAskedQuestionCategoryData),
      [frequentlyAskedQuestionCategoryData],
    ),
    disabled: isFormDisabled,
  });

  const { register, handleSubmit, setError, formState, reset, control } = form;
  const { errors } = formState;

  const onSubmit: SubmitHandler<
    UpdateFrequentlyAskedQuestionCategoryByIdDto["body"]
  > = (data) => {
    const params = {
      frequentlyAskedQuestionCategoryId,
    };
    mutateUpdate(
      { params, body: data },
      {
        onSuccess: (data) => {
          const { name } = data.frequentlyAskedQuestionCategory;
          toast.success(
            `Frequently asked question category "${name}" is updated successfully.`,
          );
        },
        onError: (error) => {
          const message = formatErrorMessage(error);
          setError("root", { message });
        },
      },
    );
  };

  useEffect(() => {
    reset(formatDefaultValues(frequentlyAskedQuestionCategoryData));
  }, [reset, frequentlyAskedQuestionCategoryData]);

  return (
    <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
      <Card>
        <CardContent>
          {isFrequentlyAskedQuestionCategoryError ? (
            <Alert severity="error">
              {formatErrorMessage(frequentlyAskedQuestionCategoryError)}
            </Alert>
          ) : null}
          <Typography gutterBottom variant="h5" component="div">
            {`Detail of Category with Id: ${frequentlyAskedQuestionCategoryId}`}
          </Typography>
          <form>
            <Stack
              sx={{
                marginTop: "0.5rem",
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <TextField
                label="Name"
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
              />

              <Stack direction="row" spacing={2}>
                <TextField
                  style={{ flexGrow: 1 }}
                  label="Priority"
                  {...register("priority")}
                  error={!!errors.priority}
                  helperText={errors.priority?.message}
                />

                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} onChange={onChange} />}
                      label="Is Active"
                    />
                  )}
                />
              </Stack>

              <LoadingButton
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                loading={isUpdateLoading}
                loadingPosition="start"
                startIcon={<EditIcon />}
                variant="contained"
              >
                <span>Update</span>
              </LoadingButton>

              {isUpdateSuccess && updateData ? (
                <Alert severity="success">{`Frequently asked question category "${updateData.frequentlyAskedQuestionCategory.name}" is updated successfully.`}</Alert>
              ) : null}
              {errors.root?.message ? (
                <Alert severity="error">{errors.root?.message}</Alert>
              ) : null}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

const formatDefaultValues = (
  data: GetFrequentlyAskedQuestionCategoryByIdResponse | undefined,
) => {
  if (!data?.frequentlyAskedQuestionCategory) {
    return {
      name: "Loading...",
      priority: 0,
      isActive: false,
    };
  }

  const name = data.frequentlyAskedQuestionCategory.name;
  const priority = data.frequentlyAskedQuestionCategory.priority;
  const isActive = data.frequentlyAskedQuestionCategory.isActive;

  return {
    name,
    priority,
    isActive,
  };
};
