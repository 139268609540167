import {
  FrequentlyAskedQuestionCategoriesHeader,
  FrequentlyAskedQuestionCategoriesTable,
} from "../../Components/FrequentlyAskedQuestionCategories/FrequentlyAskedQuestionCategories";

const FrequentlyAskedQuestionCategories = () => {
  return (
    <>
      <div className="content">
        <FrequentlyAskedQuestionCategoriesHeader />
        <FrequentlyAskedQuestionCategoriesTable />
      </div>
    </>
  );
};

export default FrequentlyAskedQuestionCategories;
