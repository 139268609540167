import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetch } from "../../../util/axios";
import { FrequentlyAskedQuestionCategoryResponse } from "../types";
import {
  GetFrequentlyAskedQuestionCategoryByIdParamsDto,
  GetFrequentlyAskedQuestionCategoryByIdQueryDto,
} from "../schemas";

interface UseGetFrequentlyAskedQuestionCategoryByIdData {
  params: GetFrequentlyAskedQuestionCategoryByIdParamsDto;
  query: GetFrequentlyAskedQuestionCategoryByIdQueryDto;
}

export interface GetFrequentlyAskedQuestionCategoryByIdResponse {
  frequentlyAskedQuestionCategory: FrequentlyAskedQuestionCategoryResponse;
}

export const getFrequentlyAskedQuestionCategoryById = async (
  frequentlyAskedQuestionCategoryId: number,
  query: GetFrequentlyAskedQuestionCategoryByIdQueryDto,
) => {
  const url = `/frequently-asked-question-categories/${frequentlyAskedQuestionCategoryId}`;

  const response =
    await customFetch.get<GetFrequentlyAskedQuestionCategoryByIdResponse>(url, {
      params: query,
    });
  return response.data;
};

export const useGetFrequentlyAskedQuestionCategoryById = (
  data: UseGetFrequentlyAskedQuestionCategoryByIdData,
  options?: UseQueryOptions<
    GetFrequentlyAskedQuestionCategoryByIdResponse,
    AxiosError | Error
  >,
) => {
  const { params, query } = data;
  const { frequentlyAskedQuestionCategoryId } = params;

  const q = useQuery<
    GetFrequentlyAskedQuestionCategoryByIdResponse,
    AxiosError | Error
  >({
    queryKey: [
      "frequently-asked-question-categories",
      frequentlyAskedQuestionCategoryId,
      query,
    ],
    queryFn: () =>
      getFrequentlyAskedQuestionCategoryById(
        frequentlyAskedQuestionCategoryId,
        query,
      ),
    ...options,
  });

  return q;
};
