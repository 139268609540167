import { z } from "zod";

export const getFrequentlyAskedQuestionCategoryByIdSchema = z.object({
  params: z.object({
    frequentlyAskedQuestionCategoryId: z.coerce
      .number()
      .int()
      .safe()
      .nonnegative(),
  }),
  query: z.object({}),
});

export type GetFrequentlyAskedQuestionCategoryByIdParamsDto = z.infer<
  typeof getFrequentlyAskedQuestionCategoryByIdSchema.shape.params
>;

export type GetFrequentlyAskedQuestionCategoryByIdQueryDto = z.infer<
  typeof getFrequentlyAskedQuestionCategoryByIdSchema.shape.query
>;
