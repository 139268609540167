import { z } from "zod";

export const createFrequentlyAskedQuestionCategorySchema = z.object({
  body: z.object({
    name: z
      .string({ message: "Name must be a string" })
      .trim()
      .min(1, { message: "Name must be at least 1 character long" })
      .max(255, { message: "Name must be at most 255 characters long" }),
    priority: z.coerce
      .number({ message: "Priority must be a number" })
      .int({ message: "Priority must be an integer" })
      .min(1, { message: "Priority must be at least 1" })
      .max(2048, { message: "Priority must be at most 2048" }),
    isActive: z.boolean({ message: "isActive must be a boolean" }),
  }),
});

export type CreateFrequentlyAskedQuestionCategoryDto = z.infer<
  typeof createFrequentlyAskedQuestionCategorySchema.shape.body
>;
