import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { CreateFrequentlyAskedQuestionCategoryDto } from "../schemas/createFrequentlyAskedQuestionCategorySchema";
import { FrequentlyAskedQuestionCategoryResponse } from "../types";
import { customFetch } from "../../../util/axios";

interface CreateFrequentlyAskedQuestionCategoryResponse {
  frequentlyAskedQuestionCategory: FrequentlyAskedQuestionCategoryResponse;
}

const createFrequentlyAskedQuestionCategory = async (
  body: CreateFrequentlyAskedQuestionCategoryDto,
) => {
  const response = await customFetch.post(
    "/frequently-asked-question-categories",
    body,
  );
  return response.data;
};

export const useCreateFrequentlyAskedQuestionCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    CreateFrequentlyAskedQuestionCategoryResponse,
    AxiosError | Error,
    CreateFrequentlyAskedQuestionCategoryDto
  >({
    mutationFn: createFrequentlyAskedQuestionCategory,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-question-categories"],
      }),
  });

  return mutation;
};
