import Button, { ButtonOwnProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MouseEventHandler, ReactNode, useState } from "react";

interface AlertDialogProps {
  children: ReactNode;
  onClick: () => void;
  dialogTitle: ReactNode;
  dialogContent: ReactNode;
  closeButtonText: string;
  submitButtonText: string;
  submitButtonColor?: ButtonOwnProps["color"];
}

export default function AlertDialog(props: AlertDialogProps) {
  const {
    children,
    onClick,
    dialogTitle,
    dialogContent,
    closeButtonText,
    submitButtonText,
    submitButtonColor,
  } = props;

  const [open, setOpen] = useState(false);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = () => {
    onClick();
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleClickOpen}>{children}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{closeButtonText}</Button>
          <Button color={submitButtonColor} onClick={handleSubmit} autoFocus>
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
