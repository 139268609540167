import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateFrequentlyAskedQuestionDto } from "../schemas/createFrequentlyAskedQuestionSchema";
import { FrequentlyAskedQuestionResponse } from "../types";
import { customFetch } from "../../../util/axios";
import { AxiosError } from "axios";

interface CreateFrequentlyAskedQuestionResponse {
  frequentlyAskedQuestion: FrequentlyAskedQuestionResponse;
}

const createFrequentlyAskedQuestion = async (
  body: CreateFrequentlyAskedQuestionDto,
) => {
  const response = await customFetch.post("/frequently-asked-questions", body);
  return response.data;
};

export const useCreateFrequentlyAskedQuestion = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    CreateFrequentlyAskedQuestionResponse,
    AxiosError | Error,
    CreateFrequentlyAskedQuestionDto
  >({
    mutationFn: createFrequentlyAskedQuestion,
    onSuccess: (res) => {
      const categoryId = res.frequentlyAskedQuestion.category.id;

      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-questions"],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "frequently-asked-question-categories",
          categoryId,
          "frequently-asked-questions",
        ],
      });
    },
  });

  return mutation;
};
