import { z } from "zod";

export const deleteFrequentlyAskedQuestionCategoryByIdSchema = z.object({
  params: z.object({
    frequentlyAskedQuestionCategoryId: z.coerce
      .number()
      .int()
      .safe()
      .nonnegative(),
  }),
});

export type DeleteFrequentlyAskedQuestionCategoryByIdParams = z.infer<
  typeof deleteFrequentlyAskedQuestionCategoryByIdSchema.shape.params
>;
