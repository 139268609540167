import { useParams } from "react-router-dom";
import {
  FrequentlyAskedQuestionsByCategoryIdHeader,
  FrequentlyAskedQuestionsByCategoryIdTable,
} from "../../Components/FrequentlyAskedQuestionCategories/FrequentlyAskedQuestionsByCategoryId";
import { FrequentlyAskedQuestionCategoryDetail } from "../../Components/FrequentlyAskedQuestionCategories/FrequentlyAskedQuestionCategories";

const FrequentlyAskedQuestionsByCategoryId = () => {
  const params = useParams<{
    frequentlyAskedQuestionCategoryId: string;
  }>();

  const frequentlyAskedQuestionCategoryId = parseInt(
    params.frequentlyAskedQuestionCategoryId || "0",
  );

  return (
    <>
      <div className="content">
        <FrequentlyAskedQuestionsByCategoryIdHeader />
        <FrequentlyAskedQuestionCategoryDetail
          frequentlyAskedQuestionCategoryId={frequentlyAskedQuestionCategoryId}
        />
        <FrequentlyAskedQuestionsByCategoryIdTable
          frequentlyAskedQuestionCategoryId={frequentlyAskedQuestionCategoryId}
        />
      </div>
    </>
  );
};

export default FrequentlyAskedQuestionsByCategoryId;
