import { z } from "zod";

export const deleteFrequentlyAskedQuestionByIdSchema = z.object({
  params: z.object({
    frequentlyAskedQuestionId: z.coerce.number().int().safe().nonnegative(),
  }),
});

export type DeleteFrequentlyAskedQuestionByIdParams = z.infer<
  typeof deleteFrequentlyAskedQuestionByIdSchema.shape.params
>;
