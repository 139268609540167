import { FormattedMessage } from "react-intl";

export const FrequentlyAskedQuestionCategoriesHeader = () => {
  return (
    <header className="page-header">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h1>
            <FormattedMessage
              id="admin.frequentlyAskedQuestionCategories"
              defaultMessage="Admin FAQ Categories"
            />
          </h1>
        </div>
      </div>
    </header>
  );
};

export default FrequentlyAskedQuestionCategoriesHeader;
