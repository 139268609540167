import { z } from "zod";
import { parseStringToJson } from "../../../util/zod";
import { FrequentlyAskedQuestion } from "../types";

export const getAllFrequentlyAskedQuestionsSchema = z.object({
  query: z
    .object({
      globalFilter: z.string().trim().optional().default(""),
      columnFilters: z
        .array(
          parseStringToJson(
            z.union([
              z.object({
                id: z.nativeEnum(
                  FrequentlyAskedQuestion.stringFilterableColumns,
                ),
                value: z.string().trim(),
              }),
              z.object({
                id: z.nativeEnum(
                  FrequentlyAskedQuestion.datetimeRangeFilterableColumns,
                ),
                value: z.array(z.string().datetime().or(z.null())).length(2),
              }),
            ]),
          ),
        )
        .optional()
        .default([]),
      sorting: z
        .array(
          parseStringToJson(
            z
              .object({
                id: z.nativeEnum(FrequentlyAskedQuestion.sortableColumns),
                desc: z.boolean().transform((desc) => (desc ? "DESC" : "ASC")),
              })
              .transform(({ id, desc }) => ({ [`${id}`]: desc })),
          ),
        )
        .optional()
        .default([]),
      pageIndex: z.coerce
        .number()
        .safe()
        .int()
        .nonnegative()
        .optional()
        .default(0),
      pageSize: z.coerce
        .number()
        .safe()
        .int()
        .positive()
        .max(100)
        .optional()
        .default(10),
    })

    .transform((val) => {
      const { columnFilters, pageIndex, pageSize, ...rest } = val;
      return {
        ...rest,
        pageIndex,
        limit: pageSize,
        offset: pageIndex * pageSize,
        stringFilterableColumnFilters: columnFilters.filter(
          ({ id }) =>
            id && id in FrequentlyAskedQuestion.stringFilterableColumns,
        ),
        datetimeRangeFilterableColumnFilters: columnFilters.filter(
          ({ id }) =>
            id && id in FrequentlyAskedQuestion.datetimeRangeFilterableColumns,
        ),
      };
    }),
});

const getAllFrequentlyAskedQuestionsQueryDto =
  getAllFrequentlyAskedQuestionsSchema.shape.query;
export type GetAllFrequentlyAskedQuestionsQueryDto = z.input<
  typeof getAllFrequentlyAskedQuestionsQueryDto
>;
