import { z } from "zod";

export const createFrequentlyAskedQuestionSchema = z.object({
  body: z.object({
    question: z
      .string({ message: "Question must be a string" })
      .trim()
      .min(1, { message: "Question must be at least 1 character long" })
      .max(255, { message: "Question must be at most 255 characters long" }),
    answer: z
      .string({ message: "Answer must be a string" })
      .trim()
      .min(1, { message: "Answer must be at least 1 character long" })
      .max(65536, { message: "Answer must be at most 65536 characters long" }),
    priority: z.coerce
      .number({ message: "Priority must be a number" })
      .int({ message: "Priority must be an integer" })
      .min(1, { message: "Priority must be at least 1" })
      .max(2048, { message: "Priority must be at most 2048" }),
    isActive: z.boolean({ message: "isActive must be a boolean" }),
    category: z.object({
      id: z
        .number({ message: "Category is required" })
        .int({ message: "Category is required" })
        .safe({ message: "Category is required" })
        .positive({ message: "Category is required" }),
    }),
  }),
});

export type CreateFrequentlyAskedQuestionDto = z.infer<
  typeof createFrequentlyAskedQuestionSchema.shape.body
>;
