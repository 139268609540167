import { z } from "zod";
import { createFrequentlyAskedQuestionCategorySchema } from "./createFrequentlyAskedQuestionCategorySchema";

export const updateFrequentlyAskedQuestionCategoryByIdSchema = z.object({
  params: z.object({
    frequentlyAskedQuestionCategoryId: z.coerce
      .number()
      .int()
      .safe()
      .nonnegative(),
  }),
  body: createFrequentlyAskedQuestionCategorySchema.shape.body,
});

export type UpdateFrequentlyAskedQuestionCategoryByIdDto = z.infer<
  typeof updateFrequentlyAskedQuestionCategoryByIdSchema
>;
