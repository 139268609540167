import { AxiosError } from "axios";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useEffect } from "react";
import { customFetch } from "../../../util/axios";
import { FrequentlyAskedQuestionCategoryResponse } from "../types";
import { GetAllFrequentlyAskedQuestionCategoriesQueryDto } from "../schemas";

export interface GetAllFrequentlyAskedQuestionCategoriesResponse {
  frequentlyAskedQuestionCategoriesCount: number;
  frequentlyAskedQuestionCategoriesTotalPages: number;
  frequentlyAskedQuestionCategoriesHasNextPage: boolean;
  frequentlyAskedQuestionCategories: FrequentlyAskedQuestionCategoryResponse[];
}

export const getAllFrequentlyAskedQuestionCategories = async (
  getAllFrequentlyAskedQuestionCategoriesQuery: GetAllFrequentlyAskedQuestionCategoriesQueryDto,
) => {
  const response = await customFetch.get(
    "/frequently-asked-question-categories",
    {
      params: getAllFrequentlyAskedQuestionCategoriesQuery,
    },
  );
  return response.data;
};

export const useGetAllFrequentlyAskedQuestionCategories = (
  getAllFrequentlyAskedQuestionCategoriesQuery: GetAllFrequentlyAskedQuestionCategoriesQueryDto,
  options?: UseQueryOptions<
    GetAllFrequentlyAskedQuestionCategoriesResponse,
    AxiosError | Error
  >,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<
    GetAllFrequentlyAskedQuestionCategoriesResponse,
    AxiosError | Error
  >({
    queryKey: [
      "frequently-asked-question-categories",
      getAllFrequentlyAskedQuestionCategoriesQuery,
    ],
    queryFn: () =>
      getAllFrequentlyAskedQuestionCategories(
        getAllFrequentlyAskedQuestionCategoriesQuery,
      ),
    keepPreviousData: true,
    ...options,
  });

  const pageIndex = getAllFrequentlyAskedQuestionCategoriesQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.frequentlyAskedQuestionCategoriesHasNextPage) {
      const nextParams = {
        ...getAllFrequentlyAskedQuestionCategoriesQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: ["frequently-asked-question-categories", nextParams],
        queryFn: () => getAllFrequentlyAskedQuestionCategories(nextParams),
      });
    }
  }, [
    getAllFrequentlyAskedQuestionCategoriesQuery,
    data,
    isPreviousData,
    pageIndex,
    queryClient,
  ]);

  return query;
};
