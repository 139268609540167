import { forwardRef, Ref } from "react";
import {
  LinkBubbleMenu,
  RichTextEditor,
  RichTextEditorProps,
  TableBubbleMenu,
  type RichTextEditorRef,
} from "mui-tiptap";
import useExtensions from "./hooks/useExtensions";
import EditorMenuControls from "./EditorMenuControls";

interface MuiTiptapEditorProps {
  content?: RichTextEditorProps["content"];
  onChange?: (html: string) => void;
}

const MuiTiptapEditor = forwardRef(function MuiTiptapEditor(
  props: MuiTiptapEditorProps,
  ref: Ref<RichTextEditorRef>,
) {
  const { content, onChange } = props;
  const extensions = useExtensions({ placeholder: "Add your answer here..." });

  return (
    <RichTextEditor
      ref={ref}
      extensions={extensions}
      content={content}
      onBlur={({ editor }) => {
        if (onChange) {
          onChange(editor.getHTML());
        }
      }}
      renderControls={() => <EditorMenuControls />}
    >
      {() => (
        <>
          <LinkBubbleMenu />
          <TableBubbleMenu />
        </>
      )}
    </RichTextEditor>
  );
});

export default MuiTiptapEditor;
