import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  CreateFrequentlyAskedQuestionDto,
  createFrequentlyAskedQuestionSchema,
  UpdateFrequentlyAskedQuestionByIdDto,
} from "../../../features/frequentlyAskedQuestions/schemas";
import {
  useCreateFrequentlyAskedQuestion,
  useUpdateFrequentlyAskedQuestionById,
} from "../../../features/frequentlyAskedQuestions/api";
import { MRT_Row } from "material-react-table";
import { FrequentlyAskedQuestionResponse } from "../../../features/frequentlyAskedQuestions/types";
import { useGetAllFrequentlyAskedQuestionCategories } from "../../../features/frequentlyAskedQuestionCategories/api";
import { formatErrorMessage } from "../../../util/utils";
import MuiTiptapEditor from "../../MuiTiptapEditor/MuiTiptapEditor";
import { type RichTextEditorRef } from "mui-tiptap";

interface CreateFrequentlyAskedQuestionModalProps {
  open: boolean;
  dataEditing: MRT_Row<FrequentlyAskedQuestionResponse> | undefined;
  onClose: () => void;
}

const CreateFrequentlyAskedQuestionModal = (
  props: CreateFrequentlyAskedQuestionModalProps,
) => {
  const { open, dataEditing, onClose } = props;
  const [keepValuesAfterSuccess, setKeepValuesAfterSuccess] = useState(false);

  const {
    data: getAllFrequentlyAskedQuestionCategoriesData,
    isLoading: isGetAllFrequentlyAskedQuestionCategoriesLoading,
    isFetching: isGetAllFrequentlyAskedQuestionCategoriesFetching,
    isError: isGetAllFrequentlyAskedQuestionCategoriesError,
  } = useGetAllFrequentlyAskedQuestionCategories({
    sorting: [JSON.stringify({ id: "name", desc: false })],
  });

  const {
    mutate: mutateCreate,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    data: createData,
  } = useCreateFrequentlyAskedQuestion();

  const {
    mutate: mutateUpdate,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    data: updateData,
    reset: updateReset,
  } = useUpdateFrequentlyAskedQuestionById();

  const muiTiptapEditorRef = useRef<RichTextEditorRef>(null);

  const form = useForm<CreateFrequentlyAskedQuestionDto>({
    resolver: zodResolver(createFrequentlyAskedQuestionSchema.shape.body),
    defaultValues: useMemo(
      () => formatDefaultValues(dataEditing),
      [dataEditing],
    ),
  });

  const { register, handleSubmit, setError, formState, reset, control } = form;
  const { errors } = formState;

  const onCreationSubmit: SubmitHandler<CreateFrequentlyAskedQuestionDto> = (
    data,
  ) => {
    mutateCreate(data, {
      onSuccess: (data) => {
        if (!keepValuesAfterSuccess) {
          if (muiTiptapEditorRef.current?.editor) {
            muiTiptapEditorRef.current.editor.commands.clearContent(true);
          }
          reset();
        }
        const { question } = data.frequentlyAskedQuestion;
        toast.success(
          `Frequently asked question "${question}" is created successfully.`,
        );
      },
      onError: (error) => {
        const message = formatErrorMessage(error);
        setError("root", { message });
      },
    });
  };

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  const onEditionSubmit: SubmitHandler<
    UpdateFrequentlyAskedQuestionByIdDto["body"]
  > = (data) => {
    if (!dataEditing) {
      return;
    }

    const params = {
      frequentlyAskedQuestionId: dataEditing.original.id,
    };
    mutateUpdate(
      { params, body: data },
      {
        onSuccess: (data) => {
          const { question } = data.frequentlyAskedQuestion;
          toast.success(
            `Frequently asked question "${question}" is updated successfully.`,
          );
        },
        onError: (error) => {
          const message = formatErrorMessage(error);
          setError("root", { message });
        },
      },
    );
  };

  const onSubmit = dataEditing ? onEditionSubmit : onCreationSubmit;
  const isSuccess = dataEditing ? isUpdateSuccess : isCreateSuccess;
  const isLoading = dataEditing ? isUpdateLoading : isCreateLoading;
  const data = dataEditing ? updateData : createData;

  useEffect(() => {
    reset(formatDefaultValues(dataEditing));
    updateReset();

    if (!open) {
      reset();
    }
  }, [open, updateReset, dataEditing, reset]);

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth={true} maxWidth="xl">
      <DialogTitle sx={{ textAlign: "center" }}>
        {dataEditing
          ? `Edit Frequently Asked Question with Id: ${dataEditing.original.id}`
          : "Create Frequently Asked Question"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            sx={{
              marginTop: "0.5rem",
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Question"
              {...register("question")}
              error={!!errors.question}
              helperText={errors.question?.message}
            />

            <div>
              <Controller
                control={control}
                name="answer"
                render={({ field: { onChange, value } }) => (
                  <MuiTiptapEditor
                    ref={muiTiptapEditorRef}
                    content={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.answer ? (
                <FormHelperText
                  error={!!errors.answer}
                  style={{ paddingLeft: "14px", paddingRight: "14px" }}
                >
                  {errors.answer?.message}
                </FormHelperText>
              ) : null}
            </div>

            <Stack direction="row" spacing={2}>
              <TextField
                style={{ flexGrow: 1 }}
                label="Priority"
                {...register("priority")}
                error={!!errors.priority}
                helperText={errors.priority?.message}
              />
              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="Is Active"
                  />
                )}
              />
            </Stack>

            <FormControl error={!!errors.category?.id}>
              <InputLabel id="category-label">Category</InputLabel>
              <Controller
                name="category.id"
                control={control}
                render={({ field }) => {
                  const categories =
                    getAllFrequentlyAskedQuestionCategoriesData?.frequentlyAskedQuestionCategories ||
                    [];

                  let placeholder = "Select a category";
                  if (
                    isGetAllFrequentlyAskedQuestionCategoriesLoading ||
                    isGetAllFrequentlyAskedQuestionCategoriesFetching
                  ) {
                    placeholder = "Loading categories...";
                  } else if (isGetAllFrequentlyAskedQuestionCategoriesError) {
                    placeholder = "Failed to load categories";
                  } else if (categories.length === 0) {
                    placeholder = "No categories found";
                  }

                  const disabled =
                    isGetAllFrequentlyAskedQuestionCategoriesLoading ||
                    isGetAllFrequentlyAskedQuestionCategoriesFetching ||
                    isGetAllFrequentlyAskedQuestionCategoriesError ||
                    !categories.length;

                  const idToCategoryName = categories.reduce(
                    (acc, category) => {
                      acc[category.id] = category.name;
                      return acc;
                    },
                    {} as Record<number, string>,
                  );

                  return (
                    <Select
                      labelId="category-label"
                      label="Category"
                      placeholder={placeholder}
                      disabled={disabled}
                      displayEmpty
                      notched={true}
                      renderValue={(selected) => {
                        if (selected === 0) {
                          return <em>{placeholder}</em>;
                        }
                        return idToCategoryName[selected];
                      }}
                      {...field}
                    >
                      <MenuItem disabled value={0}>
                        <em>{placeholder}</em>
                      </MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
              <FormHelperText error={!!errors.category?.id}>
                {errors.category?.id?.message}
              </FormHelperText>
            </FormControl>

            {isSuccess && data ? (
              <Alert severity="success">{`Frequently asked question "${data.frequentlyAskedQuestion.question}" is ${dataEditing ? "updated" : "created"} successfully.`}</Alert>
            ) : null}
            {errors.root?.message ? (
              <Alert severity="error">{errors.root?.message}</Alert>
            ) : null}
          </Stack>
        </form>
        <DevTool control={control} />
      </DialogContent>

      <DialogActions sx={{ p: "1.25rem" }}>
        {!dataEditing ? (
          <FormControlLabel
            name="keepValuesAfterSuccess"
            control={
              <Checkbox
                checked={keepValuesAfterSuccess}
                onClick={() =>
                  setKeepValuesAfterSuccess(
                    (oldKeepValuesAfterSuccess) => !oldKeepValuesAfterSuccess,
                  )
                }
              />
            }
            label="Keep values after successful submission"
          />
        ) : null}
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={dataEditing ? <EditIcon /> : <CreateIcon />}
          variant="contained"
        >
          <span>{dataEditing ? "Edit " : "Create"}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const formatDefaultValues = (
  dataEditing: MRT_Row<FrequentlyAskedQuestionResponse> | undefined,
) => {
  if (!dataEditing) {
    return {
      question: "",
      answer: "",
      priority: 1,
      isActive: true,
      category: {
        id: 0,
      },
    };
  }

  return {
    question: dataEditing.original.question,
    answer: dataEditing.original.answer,
    priority: dataEditing.original.priority,
    isActive: dataEditing.original.isActive,
    createdAt: dataEditing.original.createdAt,
    updatedAt: dataEditing.original.updatedAt,
    category: {
      id: dataEditing.original.category.id,
      name: dataEditing.original.category.name,
    },
  };
};

export default CreateFrequentlyAskedQuestionModal;
