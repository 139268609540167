import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { customFetch } from "../../../util/axios";
import { DeleteFrequentlyAskedQuestionCategoryByIdParams } from "../../frequentlyAskedQuestionCategories/schemas";

interface DeleteFrequentlyAskedQuestionCategoryResponse {}

const deleteFrequentlyAskedQuestionCategoryById = async (
  deleteFrequentlyAskedQuestionCategoryByIdParams: DeleteFrequentlyAskedQuestionCategoryByIdParams,
) => {
  const { frequentlyAskedQuestionCategoryId } =
    deleteFrequentlyAskedQuestionCategoryByIdParams;
  const response = await customFetch.delete(
    `/frequently-asked-question-categories/${frequentlyAskedQuestionCategoryId}`,
  );
  return response.data;
};

export const useDeleteFrequentlyAskedQuestionCategoryById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    DeleteFrequentlyAskedQuestionCategoryResponse,
    AxiosError | Error,
    DeleteFrequentlyAskedQuestionCategoryByIdParams
  >({
    mutationFn: deleteFrequentlyAskedQuestionCategoryById,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["frequently-asked-question-categories"],
      }),
  });

  return mutation;
};
