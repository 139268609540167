import { z } from "zod";
import { createFrequentlyAskedQuestionSchema } from "./createFrequentlyAskedQuestionSchema";

export const updateFrequentlyAskedQuestionsSchema = z.object({
  body: z.object({
    frequentlyAskedQuestions: z.array(
      createFrequentlyAskedQuestionSchema.shape.body.extend({
        id: z.number().safe().int().nonnegative(),
      }),
    ),
  }),
});

export type UpdateFrequentlyAskedQuestionsDto = z.infer<
  typeof updateFrequentlyAskedQuestionsSchema
>;
