import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateIcon from "@mui/icons-material/Create";
import useCreatePlanCategory from "../../features/planCategories/api/useCreatePlanCategory";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  CreatePlanCategoryDto,
  createPlanCategorySchema,
} from "../../features/planCategories/schemas";
import { formatErrorMessage } from "../../util/utils";

interface CreatePlanCategoryModalProps {
  open: boolean;
  onClose: () => void;
}

const CreatePlanCategoryModal = (props: CreatePlanCategoryModalProps) => {
  const { open, onClose } = props;

  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data } = useCreatePlanCategory();

  const form = useForm({
    resolver: zodResolver(createPlanCategorySchema),
    defaultValues: { name: "" },
  });
  const { register, handleSubmit, setError, formState, reset, control } = form;
  const { errors } = formState;

  const onSubmit: SubmitHandler<CreatePlanCategoryDto> = (data) => {
    mutate(data, {
      onSuccess: (data) => {
        reset();
        const { name } = data.createdPlanCategory;
        queryClient.invalidateQueries({ queryKey: ["plan-categories"] });
        toast.success(`Plan Category "${name}" is created successfully.`);
      },
      onError: (error) => {
        const message = formatErrorMessage(error);
        setError("root", { message });
      },
    });
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Create Plan Category
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            sx={{
              marginTop: "0.5rem",
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Name"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            {isSuccess && data ? (
              <Alert severity="success">{`Plan Category "${data.createdPlanCategory.name}" is created successfully.`}</Alert>
            ) : null}
            {errors.root?.message ? (
              <Alert severity="error">{errors.root?.message}</Alert>
            ) : null}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<CreateIcon />}
          variant="contained"
        >
          <span>Create</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePlanCategoryModal;
