import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  CreateGemsAdvertisementDto,
  createGemsAdvertisementSchema,
  UpdateGemsAdvertisementByIdDto,
} from "../../features/gemsAdvertisements/schemas";
import {
  useCreateGemsAdvertisement,
  useUpdateGemsAdvertisementById,
} from "../../features/gemsAdvertisements/api";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Select from "react-select";
import { useGetAllGemsClasses } from "../../features/gemsClasses/api";
import { MRT_Row } from "material-react-table";
import { GemsAdvertisementResponse } from "../../features/gemsAdvertisements/types";
import VisuallyHiddenInput from "../common/VisuallyHiddenInput";
import { useUploadSingleFile } from "../../features/uploadFiles/api/useUploadSingleFile";
import ImageWithOnClickModal from "../common/ImageWithOnClickModal";
import { formatErrorMessage } from "../../util/utils";

interface CreateGemsAdvertisementModalProps {
  open: boolean;
  dataEditing: MRT_Row<GemsAdvertisementResponse> | undefined;
  onClose: () => void;
}

const CreateGemsAdvertisementModal = (
  props: CreateGemsAdvertisementModalProps,
) => {
  const { open, dataEditing, onClose } = props;
  const [keepValuesAfterSuccess, setKeepValuesAfterSuccess] = useState(false);

  const {
    mutate: mutateCreate,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    data: createData,
  } = useCreateGemsAdvertisement();

  const {
    mutate: mutateUpdate,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    data: updateData,
    reset: updateReset,
  } = useUpdateGemsAdvertisementById();

  const {
    mutate: mutateUploadSingleFile,
    isLoading: isUploadSingleFileLoading,
    progress,
  } = useUploadSingleFile();

  const {
    data: gemsClassesDate,
    isLoading: isGemsClassesLoading,
    isFetching: isGemsClassesFetching,
  } = useGetAllGemsClasses({
    sorting: ['{"id":"id","desc":true}'],
    pageIndex: 0,
    pageSize: 0,
  });

  const form = useForm<CreateGemsAdvertisementDto>({
    resolver: zodResolver(createGemsAdvertisementSchema.shape.body),
    defaultValues: useMemo(
      () => formatDefaultValues(dataEditing),
      [dataEditing],
    ),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = form;
  const { errors } = formState;

  const imageUrl = watch("imageUrl");

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      toast.error("At least one file shall be selected.");
      return;
    }

    mutateUploadSingleFile(file, {
      onSuccess: ({ data }) => {
        setValue("imageUrl", data.location, {
          shouldDirty: true,
          shouldTouch: true,
        });
      },
      onError: (error) => {
        const message = formatErrorMessage(error);
        setError("imageUrl", { message });
      },
    });
  };

  const onCreationSubmit: SubmitHandler<CreateGemsAdvertisementDto> = (
    data,
  ) => {
    mutateCreate(data, {
      onSuccess: (data) => {
        if (!keepValuesAfterSuccess) {
          reset();
        }
        const { title } = data.gemsAdvertisement;
        toast.success(`Gems advertisement "${title}" is created successfully.`);
      },
      onError: (error) => {
        const message = formatErrorMessage(error);
        setError("root", { message });
      },
    });
  };

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  const onEditionSubmit: SubmitHandler<
    UpdateGemsAdvertisementByIdDto["body"]
  > = (data) => {
    if (!dataEditing) {
      return;
    }

    const params = { gemsAdvertisementId: dataEditing.original.id };
    mutateUpdate(
      { params, body: data },
      {
        onSuccess: (data) => {
          const { title } = data.gemsAdvertisement;
          toast.success(
            `Gems advertisement "${title}" is updated successfully.`,
          );
        },
        onError: (error) => {
          const message = formatErrorMessage(error);
          setError("root", { message });
        },
      },
    );
  };

  const onSubmit = dataEditing ? onEditionSubmit : onCreationSubmit;
  const isSuccess = dataEditing ? isUpdateSuccess : isCreateSuccess;
  const isLoading = dataEditing ? isUpdateLoading : isCreateLoading;
  const data = dataEditing ? updateData : createData;

  let allClassesOptions: { value: number; label: string }[] = [];
  if (gemsClassesDate) {
    allClassesOptions = gemsClassesDate.gemsClasses.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  const handleAddAllClasses = () => {
    setValue(
      "targetClassesIds",
      allClassesOptions.map((classOption) => classOption.value),
    );
  };

  const handleAddAllFfClasses = () => {
    const existingSelections = getValues("targetClassesIds");
    const existingSelectionsSet = new Set(existingSelections);
    const newSelections = allClassesOptions
      .filter(
        (classOption) =>
          !existingSelectionsSet.has(classOption.value) &&
          classOption.label.includes("FF"),
      )
      .map((newSelection) => newSelection.value);

    setValue("targetClassesIds", [...existingSelections, ...newSelections]);
  };

  const handleRemoveAllClasses = () => {
    setValue("targetClassesIds", []);
  };

  useEffect(() => {
    reset(formatDefaultValues(dataEditing));
    updateReset();

    if (!open) {
      reset();
    }
  }, [open, updateReset, dataEditing, reset]);

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {dataEditing
          ? `Edit Gems Advertisement with Id: ${dataEditing.original.id}`
          : "Create Gems Advertisement"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            sx={{
              marginTop: "0.5rem",
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Title"
              {...register("title")}
              error={!!errors.title}
              helperText={errors.title?.message}
            />

            <Stack direction="row" spacing={2}>
              <Controller
                control={control}
                name="imageUrl"
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isUploadSingleFileLoading}
                    style={{ flexGrow: 1 }}
                    label="Image Url"
                    error={!!errors.imageUrl}
                    helperText={errors.imageUrl?.message}
                  />
                )}
              />

              <LoadingButton
                loading={isUploadSingleFileLoading}
                loadingPosition="start"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                {isUploadSingleFileLoading ? `${progress} %` : "Upload file"}
                <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
              </LoadingButton>
            </Stack>

            {imageUrl ? <ImageWithOnClickModal src={imageUrl} /> : null}

            <Controller
              control={control}
              name="promotionUrl"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Promotion Url"
                  error={!!errors.promotionUrl}
                  helperText={errors.promotionUrl?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="startDate"
              rules={{ required: true }}
              render={({ field }) => (
                <DateTimePicker
                  label="Start Date"
                  ampm={false}
                  {...field}
                  onAccept={field.onBlur}
                  inputRef={field.ref}
                  value={field.value ? dayjs(field.value) : null}
                  slotProps={{
                    textField: {
                      error: !!errors.startDate,
                      helperText: errors.startDate?.message,
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="endDate"
              rules={{ required: true }}
              render={({ field }) => (
                <DateTimePicker
                  label="End Date (Inclusive)"
                  ampm={false}
                  {...field}
                  minDateTime={dayjs(watch("startDate"))}
                  onAccept={field.onBlur}
                  inputRef={field.ref}
                  value={field.value ? dayjs(field.value) : null}
                  slotProps={{
                    textField: {
                      error: !!errors.endDate,
                      helperText: errors.endDate?.message,
                    },
                  }}
                />
              )}
            />

            <div>
              <Controller
                control={control}
                name="targetClassesIds"
                render={({ field: { onBlur, onChange, value, ref } }) => (
                  <div>
                    <Select
                      ref={ref}
                      isLoading={isGemsClassesLoading || isGemsClassesFetching}
                      placeholder={<div>Target Classes...</div>}
                      options={allClassesOptions}
                      value={allClassesOptions.filter((c) =>
                        value.includes(c.value),
                      )}
                      onBlur={onBlur}
                      onChange={(val) => onChange(val.map((c) => c.value))}
                      isMulti
                      closeMenuOnSelect={false}
                      menuPlacement="top"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          boxShadow: errors.targetClassesIds
                            ? state.isFocused
                              ? "0 0 0 1px #d32f2f"
                              : "0 0 0 2px #3977c3"
                            : state.isFocused
                              ? "0 0 0 2px #3977c3"
                              : baseStyles.boxShadow,
                          borderColor: errors.targetClassesIds
                            ? "#d32f2f"
                            : "#c4c4c4",
                          "&:hover": {
                            borderColor: errors.targetClassesIds
                              ? "#d32f2f"
                              : "#212121",
                          },
                          "&:focus": {
                            borderColor: errors.targetClassesIds
                              ? "#d32f2f"
                              : baseStyles[":focus"]?.borderColor,
                          },
                        }),
                      }}
                    />
                    <FormHelperText
                      disabled={!errors.targetClassesIds}
                      variant="filled"
                      filled={true}
                      error={true}
                    >
                      {errors?.targetClassesIds?.message}
                    </FormHelperText>
                  </div>
                )}
              />

              <Stack direction="row" spacing={2}>
                <Button size="small" onClick={handleAddAllClasses}>
                  Add all classes
                </Button>
                <Button size="small" onClick={handleAddAllFfClasses}>
                  Add all ff classes
                </Button>
                <Button size="small" onClick={handleRemoveAllClasses}>
                  Remove all classes
                </Button>
              </Stack>
            </div>

            {isSuccess && data ? (
              <Alert severity="success">{`Gems advertisement "${data.gemsAdvertisement.title}" is ${dataEditing ? "updated" : "created"} successfully.`}</Alert>
            ) : null}
            {errors.root?.message ? (
              <Alert severity="error">{errors.root?.message}</Alert>
            ) : null}
          </Stack>
        </form>
      </DialogContent>

      <DialogActions sx={{ p: "1.25rem" }}>
        {!dataEditing ? (
          <FormControlLabel
            name="keepValuesAfterSuccess"
            control={
              <Checkbox
                checked={keepValuesAfterSuccess}
                onClick={() =>
                  setKeepValuesAfterSuccess(
                    (oldKeepValuesAfterSuccess) => !oldKeepValuesAfterSuccess,
                  )
                }
              />
            }
            label="Keep values after successful submission"
          />
        ) : null}
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={dataEditing ? <EditIcon /> : <CreateIcon />}
          variant="contained"
        >
          <span>{dataEditing ? "Edit " : "Create"}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const formatDefaultValues = (
  dataEditing: MRT_Row<GemsAdvertisementResponse> | undefined,
) => {
  if (!dataEditing) {
    return {
      imageUrl: "",
      promotionUrl: "",
      targetClassesIds: [],
    };
  }

  const targetClassesIds = dataEditing.original.targetClasses.map(
    (targetClass) => targetClass.id,
  );
  const title = dataEditing.original.title;
  const imageUrl = dataEditing.original.imageUrl;
  const promotionUrl = dataEditing.original.promotionUrl;
  const startDate = new Date(dataEditing.original.startDate);
  const endDate = new Date(dataEditing.original.endDate);

  return {
    title,
    imageUrl,
    promotionUrl,
    startDate,
    endDate,
    targetClassesIds,
  };
};

export default CreateGemsAdvertisementModal;
