export class FrequentlyAskedQuestionCategory {
  id: number;
  name: string;
  priority: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;

  static get sortableColumns(): Partial<
    Record<
      keyof FrequentlyAskedQuestionCategory,
      keyof FrequentlyAskedQuestionCategory
    >
  > {
    return {
      id: "id",
      name: "name",
      priority: "priority",
      createdAt: "createdAt",
      updatedAt: "updatedAt",
    } as const;
  }

  static get stringFilterableColumns(): Partial<
    Record<
      keyof FrequentlyAskedQuestionCategory,
      keyof FrequentlyAskedQuestionCategory
    >
  > {
    return {
      id: "id",
      name: "name",
      priority: "priority",
    } as const;
  }

  static get datetimeRangeFilterableColumns(): Partial<
    Record<
      keyof FrequentlyAskedQuestionCategory,
      keyof FrequentlyAskedQuestionCategory
    >
  > {
    return {
      createdAt: "createdAt",
      updatedAt: "updatedAt",
    } as const;
  }
}
