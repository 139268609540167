import {
  memo,
  useState,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  MRT_Row,
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CreateFrequentlyAskedQuestionModal from "./CreateFrequentlyAskedQuestionModal";
import {
  GetAllFrequentlyAskedQuestionsResponse,
  useDeleteFrequentlyAskedQuestionById,
  useUpdateFrequentlyAskedQuestions,
} from "../../../features/frequentlyAskedQuestions/api";
import { useSearchParams } from "react-router-dom";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { FrequentlyAskedQuestionResponse } from "../../../features/frequentlyAskedQuestions/types";
import AlertDialog from "../../common/AlertDialog";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { createFrequentlyAskedQuestionSchema } from "../../../features/frequentlyAskedQuestions/schemas";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { Switch } from "@mui/material";
import { useGetAllFrequentlyAskedQuestionsByCategoryId } from "../../../features/frequentlyAskedQuestionCategories/api";
import { formatErrorMessage } from "../../../util/utils";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import MuiTiptapEditorReadOnly from "../../MuiTiptapEditor/MuiTiptapEditorReadOnly";

type CreateFrequentlyAskedQuestionDtoKeys =
  keyof typeof createFrequentlyAskedQuestionSchema.shape.body.shape;
type CreateFrequentlyAskedQuestionDtoValues = {
  [K in CreateFrequentlyAskedQuestionDtoKeys]: (typeof createFrequentlyAskedQuestionSchema.shape.body.shape)[K] extends z.Schema<
    infer T
  >
    ? T
    : never;
};
type ManuallyValidateFieldResult<
  K extends CreateFrequentlyAskedQuestionDtoKeys,
> = {
  [P in K]?: CreateFrequentlyAskedQuestionDtoValues[K];
} & { errorMessage: string };

interface FrequentlyAskedQuestionsByCategoryIdTableProps {
  frequentlyAskedQuestionCategoryId: number;
}

const columnHelper =
  createMRTColumnHelper<
    GetAllFrequentlyAskedQuestionsResponse["frequentlyAskedQuestions"][number]
  >();

export const FrequentlyAskedQuestionsByCategoryIdTable = (
  props: FrequentlyAskedQuestionsByCategoryIdTableProps,
) => {
  const { frequentlyAskedQuestionCategoryId } = props;

  const [modalOpen, setModalOpen] = useState<{
    open: boolean;
    dataEditing: MRT_Row<FrequentlyAskedQuestionResponse> | undefined;
  }>({
    open: false,
    dataEditing: undefined,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [isReorderingModeEnabled, setIsReorderingModeEnabled] = useState(false);
  const [cellValidationErrors, setCellValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    searchParams.getAll("columnFilters").map((s) => JSON.parse(s)),
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const [sorting, setSorting] = useState<MRT_SortingState>([
    ...searchParams.getAll("sorting").map((s) => JSON.parse(s)),
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>(
    JSON.parse(
      searchParams.get("pagination") || '{"pageIndex":0,"pageSize":10}',
    ),
  );

  const { data, isError, isFetching, isLoading, refetch } =
    useGetAllFrequentlyAskedQuestionsByCategoryId(
      {
        params: { frequentlyAskedQuestionCategoryId },
        query: {
          globalFilter,
          columnFilters,
          sorting,
          ...pagination,
        },
      },
      { refetchOnWindowFocus: !isReorderingModeEnabled },
    );

  const initialFrequentlyAskedQuestions = useMemo(() => {
    if (!data) {
      return [];
    }
    return structuredClone(data.frequentlyAskedQuestions);
  }, [data]);

  const [orderedFrequentlyAskedQuestions, setOrderedFrequentlyAskedQuestions] =
    useState<FrequentlyAskedQuestionResponse[]>([]);

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: "Id",
      minSize: 64,
      maxSize: 128,
      enableEditing: false,
    }),
    columnHelper.accessor((row) => row.question, {
      id: "question",
      header: "Question",
      muiEditTextFieldProps: ({ cell, row }) => ({
        error: !!cellValidationErrors[cell.id],
        helperText: cellValidationErrors[cell.id],
        onBlur: (event) => {
          const { errorMessage, question } = manuallyValidateField(
            "question",
            event?.currentTarget?.value,
          );
          setCellValidationErrors((oldCellValidationErrors) => ({
            ...oldCellValidationErrors,
            [cell.id]: errorMessage,
          }));

          if (question !== undefined) {
            setOrderedFrequentlyAskedQuestions((oldOrderedCategories) => {
              const newOrderedCategories =
                structuredClone(oldOrderedCategories);
              newOrderedCategories[row.index] = {
                ...newOrderedCategories[row.index],
                question,
              };
              return newOrderedCategories;
            });
          }
        },
      }),
    }),
    columnHelper.accessor((row) => row.isActive, {
      id: "isActive",
      header: "Is Active",
      Cell: ({ cell }) => <Switch checked={cell.getValue()} disabled />,
      Edit: ({ cell, row }) => (
        <Switch
          checked={cell.getValue()}
          onChange={(event) => {
            setOrderedFrequentlyAskedQuestions((oldOrderedCategories) => {
              const newOrderedCategories =
                structuredClone(oldOrderedCategories);
              newOrderedCategories[row.index] = {
                ...newOrderedCategories[row.index],
                isActive: event.target.checked,
              };
              return newOrderedCategories;
            });
          }}
        />
      ),
      filterVariant: "checkbox",
    }),
    columnHelper.accessor((row) => row.priority, {
      id: "priority",
      header: "Priority",
      muiEditTextFieldProps: ({ cell, row }) => ({
        error: !!cellValidationErrors[cell.id],
        helperText: cellValidationErrors[cell.id],
        onBlur: (event) => {
          const { errorMessage, priority } = manuallyValidateField(
            "priority",
            event?.currentTarget?.value,
          );
          setCellValidationErrors((oldCellValidationErrors) => ({
            ...oldCellValidationErrors,
            [cell.id]: errorMessage,
          }));

          if (priority !== undefined) {
            setOrderedFrequentlyAskedQuestions((oldOrderedCategories) =>
              reposition(row.index, priority, oldOrderedCategories),
            );
          }
        },
      }),
    }),
    columnHelper.accessor((row) => new Date(row.createdAt), {
      id: "createdAt",
      header: "Created At",
      enableEditing: false,
      Cell: (props) =>
        dayjs(props.cell.getValue()).format("YYYY-MM-DD HH:mm:ss (ZZ[Z])"),
      sortingFn: "datetime",
      filterVariant: "date-range",
      minSize: 360,
    }),
    columnHelper.accessor((row) => new Date(row.updatedAt), {
      id: "updatedAt",
      header: "Updated At",
      enableEditing: false,
      Cell: (props) =>
        dayjs(props.cell.getValue()).format("YYYY-MM-DD HH:mm:ss (ZZ[Z])"),
      sortingFn: "datetime",
      filterVariant: "date-range",
      minSize: 360,
    }),
  ];

  const {
    mutate: updateFrequentlyAskedQuestions,
    isLoading: isUpdatingFrequentlyAskedQuestions,
  } = useUpdateFrequentlyAskedQuestions();

  const { mutate: deleteFrequentlyAskedQuestionById } =
    useDeleteFrequentlyAskedQuestionById();

  const resetFieldsFromData = useCallback(() => {
    setOrderedFrequentlyAskedQuestions(initialFrequentlyAskedQuestions);
    setCellValidationErrors({});
  }, [initialFrequentlyAskedQuestions]);

  const enableReorderingMode = useCallback(() => {
    setIsReorderingModeEnabled(true);
    setColumnFilters([]);
    setGlobalFilter("");
    setSorting([{ id: "priority", desc: false }]);
    setPagination({ pageIndex: 0, pageSize: 0 });
  }, []);

  const disableReorderingMode = useCallback(() => {
    setIsReorderingModeEnabled(false);
    setColumnFilters([]);
    setGlobalFilter("");
    setSorting([{ id: "priority", desc: false }]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    resetFieldsFromData();
  }, [resetFieldsFromData]);

  const handleDeleteRow = (row: MRT_Row<FrequentlyAskedQuestionResponse>) => {
    const frequentlyAskedQuestionId = row.getValue<number>("id") || 0;
    deleteFrequentlyAskedQuestionById(
      { frequentlyAskedQuestionId },
      {
        onSuccess: () => {
          toast.success(
            `Frequently asked question  with id: ${frequentlyAskedQuestionId} has been deleted successfully.`,
          );
        },
      },
    );
  };

  const renderTopToolbarCustomActions = useCallback((): ReactNode => {
    const isEditingError = Object.values(cellValidationErrors).some(
      (error) => !!error,
    );

    const handleConfirmReordering = () => {
      const modifiedCategories = evaluateModifiedQuestions(
        initialFrequentlyAskedQuestions,
        orderedFrequentlyAskedQuestions,
      );

      updateFrequentlyAskedQuestions(
        { body: { frequentlyAskedQuestions: modifiedCategories } },
        {
          onSuccess: () => {
            toast.success("Categories have been reordered successfully.");
            resetFieldsFromData();
            disableReorderingMode();
          },
          onError: (error) => {
            const message = formatErrorMessage(error);
            toast.error(message);
          },
        },
      );
    };

    return (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>

        {!isReorderingModeEnabled ? (
          <>
            <Button
              color="secondary"
              onClick={() =>
                setModalOpen({ open: true, dataEditing: undefined })
              }
              variant="contained"
            >
              Create
            </Button>
            <Button
              color="warning"
              onClick={() => enableReorderingMode()}
              variant="contained"
            >
              Enable Reordering Mode
            </Button>
          </>
        ) : null}
        {isReorderingModeEnabled ? (
          <>
            <LoadingButton
              loading={isUpdatingFrequentlyAskedQuestions}
              disabled={isEditingError || isUpdatingFrequentlyAskedQuestions}
              loadingPosition="start"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<SaveIcon />}
              onClick={handleConfirmReordering}
            >
              Save
            </LoadingButton>
            <Button
              color="success"
              onClick={() => disableReorderingMode()}
              variant="contained"
            >
              Exit
            </Button>
          </>
        ) : null}
        {isEditingError && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            color="red"
          >
            Fix errors before confirming
          </Stack>
        )}
      </Box>
    );
  }, [
    refetch,
    enableReorderingMode,
    isReorderingModeEnabled,
    cellValidationErrors,
    disableReorderingMode,
    isUpdatingFrequentlyAskedQuestions,
    initialFrequentlyAskedQuestions,
    orderedFrequentlyAskedQuestions,
    resetFieldsFromData,
    updateFrequentlyAskedQuestions,
  ]);

  const table = useMaterialReactTable({
    layoutMode: "grid",
    columns,
    data: orderedFrequentlyAskedQuestions,
    enableRowOrdering: isReorderingModeEnabled,
    enableSorting: !isReorderingModeEnabled,
    enablePagination: !isReorderingModeEnabled,
    enableEditing: isReorderingModeEnabled,
    editDisplayMode: "cell",
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableRowActions: !isReorderingModeEnabled,
    enableColumnFilters: !isReorderingModeEnabled,
    enableSortingRemoval: false,
    displayColumnDefOptions: {
      "mrt-row-actions": { size: 150, grow: false },
    },
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell);
        queueMicrotask(() => {
          const field = table.refs.editInputRefs.current?.[column.id];
          if (field) {
            field.focus();
            field.select?.();
          }
        });
      },
    }),
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const hoveredRow = table.getState()
          .hoveredRow as MRT_Row<FrequentlyAskedQuestionResponse>;
        const draggingRow = table.getState().draggingRow;

        if (hoveredRow && draggingRow) {
          const sourceIndex = draggingRow.index;
          const targetIndex = hoveredRow.index;
          setOrderedFrequentlyAskedQuestions((oldOrderedCategories) =>
            recalculatePriority(sourceIndex, targetIndex, oldOrderedCategories),
          );
        }
      },
    }),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
        <IconButton
          color="secondary"
          onClick={() => setModalOpen({ open: true, dataEditing: row })}
        >
          <EditIcon />
        </IconButton>
        <AlertDialog
          onClick={() => handleDeleteRow(row)}
          dialogTitle={
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
              <WarningAmberIcon color="error" />
              Confirm Deletion
            </Box>
          }
          dialogContent={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {row.original.question}
                    <Chip label={row.original.category.name} />
                  </Typography>
                  <Typography variant="body2">
                    <MuiTiptapEditorReadOnly content={row.original.answer} />
                  </Typography>
                </CardContent>
              </Card>
              <div>
                <Typography>
                  Are you sure you want to delete this item?
                </Typography>
                <Typography>This action cannot be undone.</Typography>
              </div>
            </div>
          }
          closeButtonText="close"
          submitButtonText="delete"
          submitButtonColor="error"
        >
          <IconButton color="error">
            <DeleteIcon />
          </IconButton>
        </AlertDialog>
      </Box>
    ),
    initialState: {
      density: "comfortable",
      showColumnFilters: true,
      columnVisibility: {
        createdAt: false,
        updatedAt: false,
      },
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Error loading data" }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: renderTopToolbarCustomActions,
    rowCount: data?.frequentlyAskedQuestionsCount ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
  });

  useEffect(() => {
    if (!searchParams.getAll("sorting").length) {
      setSorting([{ id: "priority", desc: false }]);
    }
  }, [searchParams]);

  useEffect(() => {
    const frequentlyAskedQuestions: FrequentlyAskedQuestionResponse[] =
      data?.frequentlyAskedQuestions || [];
    setOrderedFrequentlyAskedQuestions(frequentlyAskedQuestions);
    setCellValidationErrors({});
  }, [data]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete("columnFilters");
        columnFilters.forEach((columnFilter) =>
          params.append("columnFilters", JSON.stringify(columnFilter)),
        );
        return params;
      },
      { replace: true },
    );
  }, [columnFilters, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete("globalFilter");
        params.append("globalFilter", globalFilter);
        return params;
      },
      { replace: true },
    );
  }, [globalFilter, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete("sorting");
        sorting.forEach((sort) =>
          params.append("sorting", JSON.stringify(sort)),
        );
        return params;
      },
      { replace: true },
    );
  }, [sorting, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete("pagination");
        params.append("pagination", JSON.stringify(pagination));
        return params;
      },
      { replace: true },
    );
  }, [pagination, setSearchParams]);

  return (
    <>
      <FrequentlyAskedQuestionsListWrapper>
        <MaterialReactTable table={table} />
      </FrequentlyAskedQuestionsListWrapper>
      <CreateFrequentlyAskedQuestionModal
        {...modalOpen}
        onClose={() => setModalOpen({ open: false, dataEditing: undefined })}
      />
    </>
  );
};

const FrequentlyAskedQuestionsListWrapper = memo(
  function FrequentlyAskedQuestionsListWrapper({
    children,
  }: {
    children?: ReactNode;
  }) {
    return (
      <section className="page-content container-fluid">
        <div className="card">
          <div className="card-body p-0">
            <div className="table-responsive">{children}</div>
          </div>
        </div>
      </section>
    );
  },
);

function manuallyValidateField<K extends CreateFrequentlyAskedQuestionDtoKeys>(
  key: K,
  value: unknown,
): ManuallyValidateFieldResult<K> {
  const schema = createFrequentlyAskedQuestionSchema.shape.body.shape[key];
  const result = schema.safeParse(value);
  if (result.success) {
    return {
      [key]: result.data,
      errorMessage: "",
    } as ManuallyValidateFieldResult<K>;
  }

  return {
    [key]: undefined as never,
    errorMessage: result.error.issues[0].message,
  } as ManuallyValidateFieldResult<K>;
}

const recalculatePriority = (
  sourceIndex: number,
  targetIndex: number,
  oldOrderedCategories: FrequentlyAskedQuestionResponse[],
) => {
  const newOrderedCategories = structuredClone(oldOrderedCategories);
  newOrderedCategories.splice(
    targetIndex,
    0,
    newOrderedCategories.splice(sourceIndex, 1)[0],
  );
  const n = newOrderedCategories.length;

  if (targetIndex === 0) {
    newOrderedCategories[targetIndex].priority = 1;
    if (targetIndex + 1 < n) {
      newOrderedCategories[targetIndex].priority = Math.ceil(
        (newOrderedCategories[targetIndex].priority +
          newOrderedCategories[targetIndex + 1].priority) /
          2,
      );
    }
  }

  let i = Math.max(1, Math.min(sourceIndex, targetIndex));
  for (i; i < n - 1; i++) {
    const previousPriority = newOrderedCategories[i - 1].priority;
    const nextPriority = newOrderedCategories[i + 1].priority;
    const priority = newOrderedCategories[i].priority;

    if (previousPriority < priority && priority < nextPriority) {
      continue;
    }

    let newPriority = Math.ceil((previousPriority + nextPriority) / 2);
    if (newPriority <= previousPriority || newPriority >= nextPriority) {
      newPriority = previousPriority + 1;
    }
    newOrderedCategories[i].priority = newPriority;
  }
  if (
    i - 1 >= 0 &&
    newOrderedCategories[i].priority <= newOrderedCategories[i - 1].priority
  ) {
    newOrderedCategories[i].priority = newOrderedCategories[i - 1].priority + 1;
  }

  return newOrderedCategories;
};

const reposition = (
  index: number,
  newPriority: number,
  oldOrderedCategories: FrequentlyAskedQuestionResponse[],
) => {
  const updated = {
    ...oldOrderedCategories[index],
    priority: newPriority,
  };
  const newOrderedCategories = [
    ...structuredClone(oldOrderedCategories.slice(0, index)),
    ...structuredClone(oldOrderedCategories.slice(index + 1)),
  ];

  const insertIndex = binarySearchInsertIndex(
    newOrderedCategories,
    newPriority,
  );
  newOrderedCategories.splice(insertIndex, 0, updated);

  return recalculatePriority(insertIndex, insertIndex, newOrderedCategories);
};

const binarySearchInsertIndex = (
  categories: FrequentlyAskedQuestionResponse[],
  priority: number,
) => {
  let low = 0;
  let high = categories.length;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);
    if (categories[mid].priority < priority) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }

  return low;
};

const evaluateModifiedQuestions = (
  initialFrequentlyAskedQuestions: FrequentlyAskedQuestionResponse[],
  orderedFrequentlyAskedQuestions: FrequentlyAskedQuestionResponse[],
) => {
  const initialFrequentlyAskedQuestionsById: Record<
    number,
    FrequentlyAskedQuestionResponse
  > = initialFrequentlyAskedQuestions.reduce(
    (acc, question) => ({ ...acc, [question.id]: question }),
    {},
  );
  const modifiedFrequentlyAskedQuestions =
    orderedFrequentlyAskedQuestions.filter((question) => {
      const initialFrequentlyAskedQuestion =
        initialFrequentlyAskedQuestionsById[question.id];
      return Object.keys(initialFrequentlyAskedQuestion).some(
        (key) =>
          initialFrequentlyAskedQuestion[
            key as keyof FrequentlyAskedQuestionResponse
          ] !== question[key as keyof FrequentlyAskedQuestionResponse],
      );
    });

  return modifiedFrequentlyAskedQuestions;
};

export default FrequentlyAskedQuestionsByCategoryIdTable;
