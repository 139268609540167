import { z } from "zod";
import { createFrequentlyAskedQuestionCategorySchema } from "./createFrequentlyAskedQuestionCategorySchema";

export const updateFrequentlyAskedQuestionCategoriesSchema = z.object({
  body: z.object({
    frequentlyAskedQuestionCategories: z.array(
      createFrequentlyAskedQuestionCategorySchema.shape.body.extend({
        id: z.number().safe().int().nonnegative(),
      }),
    ),
  }),
});

export type UpdateFrequentlyAskedQuestionCategoriesDto = z.infer<
  typeof updateFrequentlyAskedQuestionCategoriesSchema
>;
